
.main-wrapper {
    .script-item {
        height: 1%;
        flex: 1;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .script-content {
            padding: 0 20px;

            ::v-deep .el-form-item {
                display: flex;
                flex-direction: column;
            }

            ::v-deep .el-form-item__content {
                margin-left: 0 !important;
            }

            ::v-deep .el-upload--picture-card {
                width: 392px;
                height: 226px;
                line-height: 226px;
            }

            .dialog-footer {
                display: flex;
                justify-content: center;
                padding: 20px 0;
            }

            .script-content-table {
                display: flex;
                flex-direction: column;

                .script-content-btn {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 10px;
                }

                ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
                    border-right: 2px solid #fff;
                }
                ::v-deep .el-table--medium td{
                    background:#F0F3FF;
                    border-bottom: 2px solid #fff
                }
                ::v-deep .el-table__body-wrapper {
                    height: auto;
                }
            }
        }
    }
    .btn-blue-admin {
        color: #FFF;
        background-color: #574DED;
        border-color: #574DED;
    }
    .btn-blue-admin:hover,
    .btn-blue-admin:focus,
    .btn-blue-admin.current {
        background: #1E33E4;
        border-color: #1E33E4;
        color: #FFF;
    }
    ::v-deep .el-textarea__inner {
        height: 80px;
        resize: none;
    }
    ::v-deep .el-input__inner::-webkit-outer-spin-button,
    ::v-deep .el-input__inner::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }
    ::v-deep .el-input__inner[type="number"] {
        -moz-appearance: textfield;
    }

    .imgBox {
        /*width: 392px;*/
        /*height: 226px;*/
        .imgUplod {
            display: flex;
            flex-direction: column;
            position: absolute;
            background: rgba(0, 0, 0, .3);
            width: 389px;
            height: 224px;
            /* line-height: 224px; */
            justify-content: center;
            ::v-deep .el-button--text {
                color: #fff;
            }
            ::v-deep .el-icon-upload2 {
                color: #fff;
            }
        }
        .bgColor {
            background: #ECEBFF;
            width: 389px;
            height: 224px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            ::v-deep .el-button--text {
                color: #333;
            }

            ::v-deep .el-upload--picture-card i {
                color: #333;
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
    }

    .top-left:before {
        content: "";
        position: absolute;
        height: 16px;
        width: 4px;
        background: #1D33E1;
        top: 102px;
        border-radius: 4px;
    }

    .left-title {
        padding-left: 10px;
    }

}

::v-deep .el-table--border::after, ::v-deep .el-table--group::after, ::v-deep .el-table::before {
    background-color: transparent;
}

::v-deep .upload-demo {
    display: flex;
    align-items: center;
}
.cover-prompt {
    color: #f56c6c;
}
.el-textarea {
    ::v-deep .el-input__count {
        color: #909399;
        background: #FFF;
        position: absolute;
        font-size: 12px;
        bottom: 4px;
        right: 10px;
        height: 20px;
        line-height: 20px;
    }
}
::v-deep .el-table tbody tr:hover > td {
    background-color: transparent;
}
